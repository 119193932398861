<template>
  <v-app>
    <v-toolbar app v-if="isLoggedIn">
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-toolbar-title class="headline text-uppercase">
        <span class="font-weight-light">{{ menu }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items dark class="mt-4 headline text-uppercase hidden-sm-and-down" v-if="isLoggedIn">RUCODEL</v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="drawer"
      absolute
      temporary
    >
      <v-list class="pa-1">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <img src="/img/networking.svg">
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>RUCODEL</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <v-list class="pt-0" dense>
         <v-divider></v-divider>
         <v-list-tile to="/" @click="menu='Aguardar'">Aguardar</v-list-tile>
         <v-divider></v-divider>
         <v-list-tile
          v-for="departamento in departamentos"
          :key="departamento.id"
          :to="'/departamento/'+departamento.id"
          @click="menu=departamento.nome"
        >{{ departamento.nome }}</v-list-tile>

        <v-divider></v-divider>
        <v-list-tile to="/terminados" @click="menu='Terminados'">Terminados</v-list-tile>
        <v-divider></v-divider>
        <v-list-tile to="/about" @click="menu='Sobre'">Sobre</v-list-tile>
        <v-list-tile @click="logout">Logout</v-list-tile>

       
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <router-view/>
    </v-content>
    <v-dialog
      v-model="pesquisa"
      width="600"
    >
    <v-card
    color="blue-grey lighten-2"
    dark
  >
    <v-card-title class="headline blue-grey lighten-3">
      Pesquisa de Serviços
    </v-card-title>
    <v-card-text>
      Procure por data do serviço HH:MM:SS. 
    </v-card-text>
    <v-card-text>
      <v-autocomplete
        v-model="pesquisaTermos"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="Description"
        item-value="API"
        label="Hora do Serviço"
        placeholder="Começe a escrever para iniciar a pesquisa..."
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>
    </v-card-text>
    <v-divider></v-divider>
    <v-expand-transition>
      
      <v-list v-if="pesquisaTermos" class="blue-grey lighten-3">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>Cliente</v-list-tile-title>
            <v-list-tile-sub-title>{{ pesquisaTermos.cliente }}</v-list-tile-sub-title>
          </v-list-tile-content>
         </v-list-tile>
         <v-list-tile v-if="pesquisaTermos.prazo != '0000-00-00'">
          <v-list-tile-content>
            <v-list-tile-title>Prazo</v-list-tile-title>
            <v-list-tile-sub-title>{{ pesquisaTermos.prazo }}</v-list-tile-sub-title>
          </v-list-tile-content>
         </v-list-tile>
         <v-list-tile
            v-for="(field, i) in pesquisaTermos.departamentos"
            :key="i"
            avatar
            @click="menu=field.departamento"
            :to="'/departamento/'+field.departamentoId"
          >
            <v-list-tile-avatar>
              <v-icon v-if="field.estado == 'done'" class="success">check_circle</v-icon>
              <v-icon v-if="field.estado == 'play'" class="error">stop</v-icon>
              <v-icon v-if="field.estado == 'stop'" class="warning">play_circle_outline</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title v-text="field.departamento"></v-list-tile-title>
              <v-list-tile-sub-title v-if="field.estado == 'done'"> Tarefa Terminada</v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="field.estado == 'play'"> Terminar Tarefa</v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="field.estado == 'stop'"> Iniciar Tarefa</v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="!field.estado">Sem Tarefas</v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action v-if="field.estado && field.estado != 'done'">
              <v-btn icon ripple>
                <v-icon color="white">info</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>

          <v-list-tile
            v-if="validateTarefas(pesquisaTermos.departamentos).length>0"
            avatar
            @click="menu='Terminados'"
            :to="'/terminados'"
          >
            <v-list-tile-avatar>
              <v-icon class="success">check_circle</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>Terminados</v-list-tile-title>
              <v-list-tile-sub-title>Todas as tarefas foram concluídas</v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="white">info</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
      </v-list>
    </v-expand-transition>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!pesquisaTermos"
        color="blue-grey darken-3"
        @click="pesquisaTermos = null"
      >Limpar <v-icon right>cancel</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-dialog>
    <v-fab-transition>
      <v-btn
        v-if="isLoggedIn"
        v-model="pesquisa"
        color="primary"
        dark
        fab
        fixed
        bottom
        right
        @click="pesquisa = !pesquisa"
      >
        <v-icon>search</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
  import _ from 'lodash'
  

  export default {
    data () {
      return {
          departamentos: [],
          menu: "Menu",
          drawer: false,
          pesquisa: null,
          pesquisaTermos: null,
          entries: [],
          loading: false,
          search: null,
          descriptionLimit: 60,
      }
    },
    watch:{
      drawer: function(){
        if (this.isLoggedIn) {
            this.getDepartamentos()
        }
      },
      search: _.debounce(function(val) {
        if(!val) return 

        // Items have already been loaded
        //if (this.items.length > 0) return

        // Items have already been requested
        if (this.loading) return

        if(val.length <= 4) return 

        this.loading = true

        this.$http
        .get("/auth/servicos/pesquisa",{params: { 'query' : val }})
        .then(response => {
            this.loading = false
            this.entries = response.data.resultados
        })
        .catch(e => {
          this.errors.push(e);
          this.loading = false
          this.entries = []
        })
      },400)
    },
    computed : {
      isLoggedIn : function(){ 
          return this.$store.getters.isLoggedIn 
      },
      fields () {
        if (!this.pesquisaTermos) return []

        return Object.keys(this.pesquisaTermos).map(key => {
          return {
            key,
            value: this.pesquisaTermos[key] || 'n/a'
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          const Description = entry.value.length > this.descriptionLimit
            ? entry.value.slice(0, this.descriptionLimit) + '...'
            : entry.value

          return Object.assign({}, entry, { Description })
        })
      }
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      validateTarefas(tarefas){
        if(_.size(tarefas)) {
          return tarefas.filter(t => t.estado && t.estado != 'done' )
        } else {
          return []
        }
        
      },
      getDepartamentos:function () {
        let self = this
        self.$http.get('/auth/departamentos-lista').then(response => {
          self.departamentos = response.data
        })
        .catch(e => {
          this.errors.push(e)
        })
      }
    },
    created: function () {
      if( this.isLoggedIn ) {
        this.getDepartamentos()
      }
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise(function (resolve) { //resolve, reject
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch('logout')
            resolve()
          }
          throw err;
        });
      });
    }
  }
</script>
