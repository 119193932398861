import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import Toasted from 'vue-toasted';
import store from './store'
import Axios from 'axios'

Vue.use(Toasted)
Vue.config.productionTip = false

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = "https://api.rucodel.pt"
//Vue.prototype.$http.defaults.baseURL = "https://rucodeldash-api.dev.weblevel.pt"
const accessToken = 'Bearer ' + localStorage.getItem('level_token') || ''

if (accessToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = accessToken
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
